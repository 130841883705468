.categories-page {
    padding: 20px;
}

.categories-controls {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.categories-table {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Commenting out to prevent conflict with products.css table-header styles
.table-header {
    display: grid;
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}
*/

.category-header {
    display: grid;
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    font-weight: 600;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.category-item {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.category-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #f8f9fa;
}

.category-image {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 4px;
    margin-right: 10px;
}

.col-name {
    display: flex;
    align-items: center;
    margin-left: 5px;
}

.no-results {
    text-align: center;
    padding: 40px;
    color: #6c757d;
}

.no-results i {
    font-size: 48px;
    margin-bottom: 16px;
}

.no-results p {
    font-size: 18px;
    margin-bottom: 8px;
}

.no-results span {
    font-size: 14px;
}