/* Reset default browser styles */
input,
textarea,
button,
select {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: 1.5;
    resize: vertical;
}

/* Reset placeholder styles */
::placeholder {
    color: #6c757d;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #6c757d;
}

::-ms-input-placeholder {
    color: #6c757d;
}

/* Then continue with your existing styles... */

.add-product-page {
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.add-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.add-product-header h2 {
    margin: 0;
    font-size: 1.5rem;
    color: #333;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    background: none;
    color: #666;
    cursor: pointer;
    transition: color 0.3s;
}

.back-button:hover {
    color: #333;
}

.add-product-form {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.error-message {
    background-color: #fff3f3;
    color: #dc3545;
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
}

.cancel-button,
.submit-button {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;
}

.cancel-button {
    background-color: #f8f9fa;
    color: #333;
}


.cancel-button:hover {
    background-color: #e9ecef;
}

.submit-button:hover {
    background-color: #0056b3;
}

.submit-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.form-group small {
    display: block;
    margin-top: 0.25rem;
    color: #666;
    font-size: 0.875rem;
}

.image-urls-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.image-url-input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.image-url-input:focus {
    border-color: #007bff;
    outline: none;
}

.delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: #c82333;
}

.delete-button:disabled {
    background-color: #dc354580;
    cursor: not-allowed;
}

/* Add to your existing styles */
.input-with-button {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.ai-button.description {
    background-color: #2196F3;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.2s;
    height: fit-content;
}

.ai-button.description:hover {
    background-color: #1976D2;
}

.ai-button.description:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.input-with-button textarea {
    flex: 1;
    min-height: 100px;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.3s;
}

.input-with-button textarea:focus {
    border-color: #007bff;
    outline: none;
}