.login-page {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
    padding: 20px;
}

.login-container {
    width: 100%;
    max-width: 440px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: var(--shadow);
    padding: 1.5rem;
    border-radius: var(--border-radius);
}

.login-header {
    text-align: center;
    margin-bottom: 0.5rem;
}

.brand-name {
    font-size: 2.5rem;
    font-weight: 700;
    color: var(--text-color);
    margin: 0;
    letter-spacing: -0.5px;
}

.brand-subtitle {
    font-size: 1.1rem;
    color: var(--text-secondary);
    margin: 0.25rem 0 0;
}

.login-footer {
    text-align: center;
    margin-top: 0.5rem;
}

.login-footer p {
    margin: 0.5rem 0;
    color: var(--text-color);
}

.login-footer a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
}

.login-footer a:hover {
    text-decoration: underline;
}

.help-text {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .login-container {
        padding: 1.5rem;
    }

    .login-logo {
        height: 32px;
    }
}