.auth-container {
    background: var(--background-color);
    padding: 1.5rem;
    border-radius: var(--border-radius);
    box-shadow: var(--shadow);
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.form-group {
    margin-bottom: 0.75rem;
}

.form-group label {
    display: block;
    color: var(--text-color);
    margin-bottom: 0.25rem;
    font-weight: 500;
}

.input-container {
    position: relative;
}

/* Remove the global input styles */
/* Delete or comment out the global input styles */
/*
input {
    width: 100%;
    height: var(--input-height);
    padding: 0 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background: var(--background-color);
    font-size: 1rem;
}

input:focus {
    outline: none;
    border-color: var(--accent-main);
}
*/

/* Add scoped input styles */
.login-form input,
.auth-form input {
    width: 100%;
    height: var(--input-height);
    padding: 0 12px !important;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background: var(--background-color);
    font-size: 1rem;
}

.login-form input:focus,
.auth-form input:focus {
    outline: none;
    border-color: var(--accent-main);
}

/* Update error states to be scoped as well */
.login-form input.error,
.auth-form input.error {
    border-color: var(--error-color);
}

.login-form input.error:focus,
.auth-form input.error:focus {
    border-color: var(--error-color);
}

/* Update select styles to be scoped */
.login-form select,
.auth-form select {
    width: 100%;
    height: var(--input-height);
    padding: 0 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background: var(--background-color);
    font-size: 1rem;
    cursor: pointer;
}

.login-form select:focus,
.auth-form select:focus {
    outline: none;
    border-color: var(--accent-main);
}

.login-form select.error,
.auth-form select.error {
    border-color: var(--error-color);
}

.password-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.forgot-password {
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.9rem;
}

.toggle-password {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-secondary);
}

.submit-button {
    width: auto;
    min-width: 120px;
    height: var(--input-height);
    background: var(--submit-button);
    color: white;
    border: none;
    border-radius: var(--border-radius);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0 24px;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background: var(--submit-button-hover);
}

.error-message {
    color: var(--error-color);
    font-size: 0.85rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.error-message i {
    font-size: 0.9rem;
}

/* Remove the phone prefix styles since we're using email */
.prefix {
    display: none;
}

/* Add these to your existing auth.css */
input.error {
    border-color: var(--error-color);
}

input.error:focus {
    border-color: var(--error-color);
}

.error-message {
    color: var(--error-color);
    font-size: 0.85rem;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}

.error-message i {
    font-size: 0.9rem;
}

/* Exception for login form button */
.auth-form .submit-button {
    width: 100%;
}

/* Add to existing auth.css */

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

select {
    width: 100%;
    height: var(--input-height);
    padding: 0 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    color: var(--text-color);
    background: var(--background-color);
    font-size: 1rem;
    cursor: pointer;
}

select:focus {
    outline: none;
    border-color: var(--accent-main);
}

select.error {
    border-color: var(--error-color);
}

.general-error {
    margin-bottom: 1rem;
    padding: 0.5rem;
    background-color: #fff5f5;
    border: 1px solid #feb2b2;
    border-radius: 4px;
    text-align: center;
}