.sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 280px;
    background: white;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.seller-profile {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.profile-image img {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
}

.seller-info {
    margin: 15px 0;
}

.seller-info h3 {
    font-size: 1.1rem;
    color: var(--text-color);
    margin-bottom: 5px;
}

.seller-info p {
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.seller-details {
    font-size: 0.9rem;
    color: var(--text-secondary);
}

.agreement,
.contract-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.sidebar-nav {
    flex: 1;
    margin-top: 20px;
}

.nav-item {
    display: flex;
    align-items: center;
    padding: 12px;
    color: var(--text-color);
    text-decoration: none;
    border-radius: 8px;
    margin-bottom: 5px;
}

.nav-item i {
    width: 24px;
    margin-right: 12px;
}

.nav-item:hover {
    background: rgba(0, 0, 0, 0.05);
}

.nav-item.active {
    background: var(--submit-button);
    color: white !important;
}

.nav-item.active:hover {
    background: var(--submit-button-hover);
}

.nav-item.active i {
    color: white;
}

.help-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.help-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 12px;
    border: none;
    background: none;
    color: var(--text-color);
    cursor: pointer;
    border-radius: 8px;
}

.help-button i {
    margin-right: 12px;
}

.help-button:hover {
    background: rgba(0, 0, 0, 0.05);
}