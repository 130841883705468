@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', sans-serif;
    line-height: 1.5;
    color: var(--text-color);
    background-color: var(--background-color);
}

input,
button {
    font-family: inherit;
}

input::placeholder {
    color: #999;
}

input:focus {
    outline: none;
    border-color: var(--primary-color);
}