.add-category-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.add-category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.add-category-header h2 {
    margin: 0;
    font-size: 24px;
}

.back-button {
    padding: 8px 16px;
    background: none;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
}

.back-button:hover {
    background: #f5f5f5;
}

.add-category-form {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 500;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
}

.cancel-button,
.submit-button {
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
}

.cancel-button {
    background: none;
    border: 1px solid #ddd;
}


.submit-button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

.error-message {
    background: #fff3f3;
    color: #dc3545;
    padding: 12px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.delete-button {
    padding: 0.75rem 1.5rem;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.delete-button:hover {
    background-color: #c82333;
}