.dashboard-page {
    padding: 20px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.page-header h1 {
    font-size: 24px;
    color: var(--text-color);
}

.content-box {
    background: white;
    border-radius: 8px;
    padding: 24px;
    min-height: 400px;
    border: 1px solid var(--border-color);
}