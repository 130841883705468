.dashboard-layout {
    display: flex;
    min-height: 100vh;
    background-color: #f5f7fb;
}

.main-content {
    flex: 1;
    margin-left: 280px;
    padding: 20px;
}

.content-area {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}