.dashboard-header {
    background: white;
    border-radius: 8px;
    padding: 16px 20px;
    position: relative;
}

.breadcrumb {
    margin-bottom: 16px;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

.breadcrumb .separator {
    margin: 0 8px;
    color: #ccc;
}

.filter-container {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    align-items: center;
}

.filter-group {
    display: flex;
    align-items: center;
    gap: 8px;
}

.filter-group span {
    color: var(--text-color);
    font-size: 0.9rem;
}

.filter-group select {
    padding: 6px 24px 6px 12px;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    color: var(--text-color);
    font-size: 0.9rem;
    background: white;
    cursor: pointer;
    outline: none;
}

.filter-group select:hover {
    border-color: var(--text-secondary);
}

.filter-group select:focus {
    border-color: var(--accent-main);
}

.header-actions {
    position: absolute;
    top: 16px;
    right: 20px;
}

.logout-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #dc3545;
    /* Red color */
    color: white;
    border: none;
    border-radius: var(--border-radius);
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
}

.logout-button:hover {
    background-color: #c82333;
    /* Darker red on hover */
}

.logout-button i {
    font-size: 0.9rem;
}