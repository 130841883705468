.settings-section {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.settings-section h2 {
    margin-bottom: 10px;
    color: #333;
}

.settings-section p {
    color: #666;
    margin-bottom: 20px;
}

.settings-generate-button {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
    margin-top: 10px;
}

.settings-generate-button:hover {
    background-color: #45a049;
}

.settings-generate-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.settings-progress-container {
    margin-top: 20px;
}

.settings-progress-bar {
    width: 100%;
    height: 20px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
}

.settings-progress-fill {
    height: 100%;
    background-color: #4CAF50;
    transition: width 0.3s ease;
}

.settings-progress-text {
    display: block;
    text-align: center;
    margin-top: 8px;
    color: #666;
}

.dashboard-page {
    padding: 20px;
    min-height: 100vh;
}

.content-box {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.debug-info {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: #333;
    font-weight: 500;
}

.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: white;
    cursor: pointer;
}

.form-group select:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}