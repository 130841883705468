.products-page {
    padding: 20px;
}

.page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.page-header h1 {
    font-size: 24px;
    color: var(--text-color);
}

.new-product-btn {
    padding: 10px 20px !important;
    display: flex !important;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem !important;
    background: var(--submit-button) !important;
}

.new-product-btn:hover {
    background: var(--submit-button-hover) !important;
}

.new-product-btn i {
    font-size: 14px;
}

.products-table {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    min-height: 200px;
    transition: opacity 0.2s ease-in-out;
}

.products-table.searching {
    opacity: 0.6;
}

.table-header {
    display: grid;
    grid-template-columns: 40px 3fr 1fr 1fr 1fr 1.5fr;
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid var(--border-color);
    font-weight: 500;
    color: var(--text-color);
}

.user-table-header {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.7fr 1.3fr 1fr;
    padding: 16px;
    background: #f8f9fa;
    border-bottom: 1px solid var(--border-color);
    font-weight: 500;
    color: var(--text-color);
}

.user-item {
    display: grid;
    grid-template-columns: 0.3fr 1fr 0.7fr 1.3fr 1fr;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.user-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #f8f9fa;
}

.table-row {
    display: grid;
    grid-template-columns: 40px 3fr 1fr 1fr 1fr 1.5fr 0.5fr;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}

.table-row:hover {
    background: #f8f9fa;
}

.status-badge {
    display: inline-block;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
}

.status-badge.published {
    background: #e6f4ea;
    color: #1e7e34;
}

.status-badge.pending {
    background: #fff3e0;
    color: #f57c00;
}

.status-badge.draft {
    background: #f1f3f4;
    color: #5f6368;
}

.col-price {
    font-weight: 500;
}

.products-controls {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 1rem;
}

.filter-container {
    margin-left: 20px;
}

.seller-filter {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    min-width: 150px;
    cursor: pointer;
}

.seller-filter:hover {
    border-color: #999;
}

.seller-filter:focus {
    outline: none;
    border-color: #0066cc;
    box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.search-icon {
    color: var(--text-secondary);
    font-size: 1.1rem;
}

.search-box input,
.filters-container select {
    width: auto;
    padding: 10px 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 0.9rem;
}

.search-box input:focus {
    outline: none;
    border-color: var(--accent-main);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 24px;
    padding: 16px;
}

.pagination-btn {
    min-width: 36px;
    height: 36px;
    padding: 0 12px;
    border: 1px solid var(--border-color);
    background: white;
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination-btn:hover:not(:disabled) {
    background: #f8f9fa;
    border-color: var(--text-secondary);
}

.pagination-btn.active {
    background: var(--submit-button);
    border-color: var(--submit-button);
    color: white;
}

.pagination-btn.active:hover {
    background: var(--submit-button-hover);
}

.pagination-btn:disabled {
    background: #f1f3f4;
    color: var(--text-secondary);
    cursor: not-allowed;
}

.no-results {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 48px 20px;
    text-align: center;
    color: var(--text-secondary);
}

.no-results i {
    font-size: 2rem;
    margin-bottom: 16px;
    opacity: 0.5;
}

.no-results p {
    font-size: 1.1rem;
    margin-bottom: 8px;
    color: var(--text-color);
}

.no-results span {
    font-size: 0.9rem;
}

/* Add Product Form Styles */
.add-product-page {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.add-product-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.add-product-header h2 {
    font-size: 24px;
    color: var(--text-color);
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid var(--border-color);
    background: white;
    border-radius: 4px;
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.9rem;
}

.back-button:hover {
    background: #f8f9fa;
}

.add-product-form {
    background: white;
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    color: var(--text-color);
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 0.9rem;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--accent-main);
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

.form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.form-group small {
    display: block;
    margin-top: 4px;
    color: var(--text-secondary);
    font-size: 0.85rem;
}

.image-urls-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.image-url-input {
    width: 100%;
    padding: 10px 12px;
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    font-size: 0.9rem;
}

.image-url-input:focus {
    outline: none;
    border-color: var(--accent-main);
}

.form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
    padding-top: 20px;
    border-top: 1px solid var(--border-color);
}

.cancel-button {
    padding: 10px 20px;
    border: 1px solid var(--border-color);
    background: white;
    border-radius: var(--border-radius);
    color: var(--text-color);
    cursor: pointer;
    font-size: 0.9rem;
}

.cancel-button:hover {
    background: #f8f9fa;
}

.submit-button {
    padding: 10px 20px;
    border: none;
    background: var(--submit-button);
    border-radius: var(--border-radius);
    color: white;
    cursor: pointer;
    font-size: 0.9rem;
}

.submit-button:hover {
    background: var(--submit-button-hover);
}

.submit-button:disabled {
    background: var(--text-secondary);
    cursor: not-allowed;
}

.error-message {
    padding: 12px 16px;
    background: #fee;
    border: 1px solid #fcc;
    border-radius: var(--border-radius);
    color: #c00;
    margin-bottom: 20px;
}

/* Status badge colors for form */
.status-badge.out_of_stock {
    background: #feebe6;
    color: #c53030;
}

.status-badge.archived {
    background: #edf2f7;
    color: #4a5568;
}

.product-item {
    display: grid;
    grid-template-columns: 40px 3fr 1fr 1fr 1fr 1.5fr;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.product-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #f8f9fa;
}

.filters-container {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.view-filter {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    min-width: 120px;
    cursor: pointer;
}

.view-filter:hover {
    border-color: #999;
}

.view-filter:focus {
    outline: none;
    border-color: #0066cc;
    box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.2);
}

.bulk-actions {
    display: none;
    gap: 8px;
    align-items: center;
    padding: 10px 12px;
    background-color: var(--background-color);
    border-radius: 4px;
}

.bulk-actions.show {
    display: flex;
}

.bulk-action-select {
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
    font-size: 14px;
    cursor: pointer;
}

.delete-button {
    padding: 8px 16px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #c82333;
}

.col-checkbox {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.col-checkbox input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: 2px solid var(--border-color);
    border-radius: 3px;
    appearance: none;
    -webkit-appearance: none;
    background-color: white;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}

.col-checkbox input[type="checkbox"]:checked {
    background-color: var(--submit-button);
    border-color: var(--submit-button);
}

.col-checkbox input[type="checkbox"]:checked::after {
    content: '✓';
    color: white;
    font-size: 12px;
    font-weight: bold;
}

.col-checkbox input[type="checkbox"]:hover {
    border-color: var(--submit-button);
}

.col-checkbox input[type="checkbox"]:focus {
    outline: 2px solid rgba(0, 102, 204, 0.2);
    outline-offset: 2px;
}

.reviews-section {
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid var(--border-color);
}

.reviews-section h3 {
    margin-bottom: 1rem;
    color: var(--text-color);
}

.existing-reviews {
    margin-bottom: 1rem;
}

.review-item {
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.review-actions {
    display: flex;
    gap: 0.5rem;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: var(--text-color);
}

.icon-button:hover {
    color: var(--primary-color);
}

.icon-button.delete:hover {
    color: var(--danger-color);
}

.ai-button {
    background-color: #4a90e2;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
}

.ai-button:hover {
    background: var(--accent-color-hover);
}

.review-edit-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.review-edit-actions {
    display: flex;
    gap: 1rem;
}

.add-review-button {
    padding: 8px 16px;
    background: var(--submit-button);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
}

.review-form {
    margin-top: 1rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 4px;
}

.review-form-actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}

.review-form-actions button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.review-form-actions button:first-child {
    background: white;
    border: 1px solid var(--border-color);
}

.review-form-actions .submit-button {
    background: var(--submit-button);
    color: white;
    border: none;
}

.review-form-actions .ai-button {
    background: #82825b;
    color: white;
    border: none;
}

.review-form-actions .ai-button:disabled {
    background: var(--text-secondary);
    cursor: not-allowed;
}

.review-form-actions .submit-button:hover {
    background: var(--submit-button-hover);
}

.review-form-actions .ai-button:hover:not(:disabled) {
    background: #82825b;
}

.input-with-button {
    display: flex;
    gap: 8px;
    align-items: center;
}

.input-with-button input {
    flex: 1;
}

.ai-button.url-check {
    background-color: #4a90e2;
    min-width: 70px;
}

.ai-button.url-check:hover:not(:disabled) {
    background-color: #357abd;
}

.ai-button.url-check:disabled {
    background-color: var(--text-secondary);
    cursor: not-allowed;
}

.ai-url-check-button {
    background-color: #bf755d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    min-width: 150px;
}

.ai-url-check-button:hover:not(:disabled) {
    background-color: #a65c44;
}

.ai-url-check-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}