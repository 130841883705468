:root {
    --text-color: #112240;
    --background-color: #ffffff;
    --submit-button: #112240;
    --submit-button-hover: #1a365d;
    --accent-main: #64FFDA;
    --text-secondary: #8892B0;
    --border-color: #e0e0e0;
    --error-color: #ff4444;

    --border-radius: 8px;
    --input-height: 48px;
    --shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}